import React, { useState, useRef, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';
import './Player.css';
import { useRecoilState } from 'recoil';
import {
  VideoInfo,
  videoInfoState,
  videoListState,
  MapPosition,
} from '../../atoms/video';
import Button from '../Button/Button';
import { convertToCSV, parseCSV } from '../../utils/csvUtils';
import { hmsToSeconds, secondsToHms } from '../../utils/timeUtils';
import { notification, Skeleton } from 'antd';
import { RecordModal } from '../RecordModal/RecordModal';
import { defaultCenter } from '../GMap';

interface PlayerProps {
  segmentStartTime?: string;
  segmentTitle?: string;
  mapPosition?: MapPosition;
}

const Player: React.FC<PlayerProps> = ({
  segmentStartTime,
  segmentTitle,
  mapPosition,
}) => {
  const [videoSrc, setVideoSrc] = useState<string | undefined>(undefined);
  const [currentTime, setCurrentTime] = useState(0);
  const [startTime, setStartTime] = useState<string | null>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const csvInputRef = useRef<HTMLInputElement>(null);
  const [videoInfo, setVideoInfo] = useRecoilState(videoInfoState);
  const [videoList, setVideoList] = useRecoilState(videoListState);
  const [isRecordModalOpen, setRecordModalOpen] = useState(false);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const fileUrl = URL.createObjectURL(file);
      setVideoSrc(fileUrl);
      setVideoInfo((prev) => ({
        ...prev,
        fileName: file.name,
      }));
      openVideoNotification([file]);
    }
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      const handleLoadedMetadata = () => {
        videoElement.currentTime = 0;
      };

      const handleTimeUpdate = () => {
        setCurrentTime(Math.floor(videoElement.currentTime)); // Ensure current time is rounded down to the nearest integer
      };

      videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);
      videoElement.addEventListener('timeupdate', handleTimeUpdate);

      return () => {
        videoElement.removeEventListener(
          'loadedmetadata',
          handleLoadedMetadata,
        );
        videoElement.removeEventListener('timeupdate', handleTimeUpdate);
      };
    }
  }, [videoSrc]);

  const handleAddTimeCode = () => {
    setStartTime(secondsToHms(currentTime));
    videoRef.current?.play();
  };

  const handleStopTimeCode = () => {
    if (startTime !== null) {
      const endHms = secondsToHms(currentTime);
      const recordData = {
        startTime,
        endTime: endHms,
      };

      // stop player, clear end mark
      videoRef.current?.pause();
      setStartTime(null);

      const newVideoInfo: VideoInfo = {
        fileName: videoInfo.fileName,
        location: mapPosition?.location ?? defaultCenter,
        key: uuidv4(),
        title: '',
        type: videoInfo.type ?? '',
        ...recordData,
      };

      setVideoInfo(newVideoInfo);

      setRecordModalOpen(true);
    }
  };

  const handleExportCSV = () => {
    const csvData = convertToCSV(videoList);
    const blob = new Blob([csvData], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${videoInfo.fileName.split('.')[0]}.csv`;
    a.click();
    URL.revokeObjectURL(url);
  };

  useEffect(() => {
    const onSave = (e: KeyboardEvent) => {
      if ((e.ctrlKey || e.metaKey) && e.key === 's') {
        e.preventDefault();
        if (!videoList?.length) return;

        handleExportCSV();
      }
    };

    window.addEventListener('keydown', onSave);
    return () => {
      window.removeEventListener('keydown', onSave);
    };
  }, []);

  const handleImportCSV = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const csvContent = e.target?.result as string;
        const importedVideoList = parseCSV(csvContent);
        setVideoList(importedVideoList);
      };
      reader.readAsText(file);
      openCsvNotification([file]);
    }
  };

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement && segmentStartTime) {
      const segmentStartSeconds = hmsToSeconds(segmentStartTime);
      console.log('Setting currentTime to:', segmentStartSeconds); // Debugging log
      if (!isNaN(segmentStartSeconds)) {
        videoElement.currentTime = segmentStartSeconds;
        videoElement.play().catch((error) => {
          console.error('Error playing video:', error);
        });
      }
    }
  }, [segmentStartTime]);

  const openVideoNotification = (files: File[]) => {
    files.forEach((file) => {
      notification.success({
        message: 'Відео додано',
        description: `Відео ${file.name} успішно додано.`,
        duration: 2,
      });
    });
  };

  const openCsvNotification = (files: File[]) => {
    files.forEach((file) => {
      notification.success({
        message: 'Мета данні додано',
        description: `Мета данні ${file.name} успішно додано.`,
        duration: 2,
      });
    });
  };

  return (
    <div className="Player_Container">
      <div>
        <h3>
          Video Player <span className="Segment_Title">{segmentTitle}</span>
        </h3>
        <input
          type="file"
          onChange={handleFileChange}
          style={{ display: 'none' }}
          ref={fileInputRef}
        />
        <Button
          appearance="primary"
          handleClick={() => fileInputRef.current?.click()}
        >
          Choose Video
        </Button>
        {!videoSrc && <Skeleton paragraph={{ rows: 4 }} />}
        {videoSrc && (
          <div>
            <video key={videoSrc} ref={videoRef} width="95%" controls>
              <source src={videoSrc} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
        )}
      </div>
      <div className="Buttons_Container">
        {startTime === null && (
          <Button
            appearance="start"
            handleClick={handleAddTimeCode}
            disabled={!videoInfo.fileName}
          >
            Add time code
          </Button>
        )}
        {startTime !== null && (
          <Button appearance="stop" handleClick={handleStopTimeCode}>
            Stop time code
          </Button>
        )}
        <Button
          appearance="primary"
          handleClick={handleExportCSV}
          disabled={videoList.length === 0}
        >
          Export time code
        </Button>
        <input
          ref={csvInputRef}
          type="file"
          accept=".csv"
          onChange={handleImportCSV}
          style={{ display: 'none' }}
        />
        <Button
          appearance="primary"
          handleClick={() => csvInputRef.current?.click()}
          disabled={!videoInfo.fileName}
        >
          Import time code
        </Button>
      </div>

      {isRecordModalOpen && (
        <RecordModal
          open={isRecordModalOpen}
          mapPosition={mapPosition}
          onClose={() => setRecordModalOpen(false)}
        />
      )}
    </div>
  );
};

export default Player;
