import React, { ReactElement } from 'react';
import { renderToString } from 'react-dom/server';
import { TimeCodeType } from '../atoms/video';
import { ReactComponent as BarriersSvg } from '../atoms/markers/barriers.svg';
import { ReactComponent as CivilCarMoveSvg } from '../atoms/markers/civil_car_move.svg';
import { ReactComponent as CivilCarSvg } from '../atoms/markers/civil_car.svg';
import { ReactComponent as CivilPersonSvg } from '../atoms/markers/civil_person.svg';
import { ReactComponent as EnemyVehicleMoveSvg } from '../atoms/markers/enemy_vehicle_move.svg';
import { ReactComponent as EnemyVehicleSvg } from '../atoms/markers/enemy_vehicle.svg';
import { ReactComponent as EnemySvg } from '../atoms/markers/enemy.svg';
import { ReactComponent as MinesSvg } from '../atoms/markers/mines.svg';
import { ReactComponent as OkSvg } from '../atoms/markers/ok.svg';
import { ReactComponent as SpSvg } from '../atoms/markers/sp.svg';
import { getInlineSvgSrc } from '../utils/imgUtils';

type IconStyles = 'default' | 'active' | 'otherSegment';

type MarkersByTypeMap = Record<
  TimeCodeType,
  Record<IconStyles, ReactElement | null>
>;

interface UseMarkersIconsMap {
  iconsMap: MarkersByTypeMap;
  getIcon: (type: TimeCodeType, style?: IconStyles) => ReactElement | null;
  getIconSrc: (type: TimeCodeType, style?: IconStyles) => string;
}

const iconsMap: MarkersByTypeMap = {
  '': {
    default: null,
    active: null,
    otherSegment: null,
  },
  barriers: {
    default: <BarriersSvg fill="grey" stroke="white" />,
    active: <BarriersSvg fill="green" stroke="white" />,
    otherSegment: <BarriersSvg fill="red" stroke="white" />,
  },
  civil_car_move: {
    default: <CivilCarMoveSvg fill="grey" stroke="white" />,
    active: <CivilCarMoveSvg fill="green" stroke="white" />,
    otherSegment: <CivilCarMoveSvg fill="red" stroke="white" />,
  },
  civil_car: {
    default: <CivilCarSvg fill="grey" stroke="white" />,
    active: <CivilCarSvg fill="green" stroke="white" />,
    otherSegment: <CivilCarSvg fill="red" stroke="white" />,
  },
  civil_person: {
    default: <CivilPersonSvg fill="grey" stroke="white" />,
    active: <CivilPersonSvg fill="green" stroke="white" />,
    otherSegment: <CivilPersonSvg fill="red" stroke="white" />,
  },
  enemy_vehicle_move: {
    default: <EnemyVehicleMoveSvg fill="grey" stroke="white" />,
    active: <EnemyVehicleMoveSvg fill="green" stroke="white" />,
    otherSegment: <EnemyVehicleMoveSvg fill="red" stroke="white" />,
  },
  enemy_vehicle: {
    default: <EnemyVehicleSvg fill="grey" stroke="white" />,
    active: <EnemyVehicleSvg fill="green" stroke="white" />,
    otherSegment: <EnemyVehicleSvg fill="red" stroke="white" />,
  },
  enemy: {
    default: <EnemySvg fill="grey" stroke="white" />,
    active: <EnemySvg fill="green" stroke="white" />,
    otherSegment: <EnemySvg fill="red" stroke="white" />,
  },
  mines: {
    default: <MinesSvg fill="grey" stroke="white" />,
    active: <MinesSvg fill="green" stroke="white" />,
    otherSegment: <MinesSvg fill="red" stroke="white" />,
  },
  ok: {
    default: <OkSvg fill="grey" stroke="white" />,
    active: <OkSvg fill="green" stroke="white" />,
    otherSegment: <OkSvg fill="red" stroke="white" />,
  },
  sp: {
    default: <SpSvg fill="grey" stroke="white" />,
    active: <SpSvg fill="green" stroke="white" />,
    otherSegment: <SpSvg fill="red" stroke="white" />,
  },
};

export const useMarkersIconsMap = (): UseMarkersIconsMap => {
  const getIcon = (type: TimeCodeType, style: IconStyles = 'default') => {
    return iconsMap[type][style];
  };

  const getIconSrc = (
    type: TimeCodeType,
    style: IconStyles = 'default',
  ): string => {
    const iconElement = iconsMap[type][style];
    return iconElement ? getInlineSvgSrc(renderToString(iconElement)) : '';
  };

  return { iconsMap, getIconSrc, getIcon };
};
