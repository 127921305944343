import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  filteredVideoListState,
  VideoInfo,
  videoListState,
  Location,
} from '../../atoms/video';
import GMap, { GMapProps } from '../GMap/GMap';

type Props = Omit<GMapProps, 'videoList' | 'markerLocationUpdate'>;

export const EditorGMap: React.FC<Props> = (props) => {
  const [, setVideoList] = useRecoilState(videoListState);
  const videoList = useRecoilValue(filteredVideoListState);

  const handleLocationUpdate = (segment: VideoInfo, location: Location) => {
    setVideoList((prevList) => {
      return prevList.map((seg) => {
        if (seg.key === segment.key) {
          return {
            ...seg,
            location,
          };
        }
        return seg;
      });
    });
  };

  return (
    <GMap
      videoList={videoList}
      markerLocationUpdate={handleLocationUpdate}
      {...props}
    />
  );
};
