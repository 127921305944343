import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import {
  GoogleMap,
  MarkerF,
  PolylineF,
  useJsApiLoader,
} from '@react-google-maps/api';
import './GMap.css';
import { filteredVideoListState, VideoInfo } from '../../atoms/video';
import { useRecoilValue } from 'recoil';
import { loadFiles } from '../../utils/kmXLoadUtils';
import { parse } from '../../utils/kmXParseUtils';
import Button from '../Button/Button';
import { useMarkersIconsMap } from '../../hooks/useMarkersIconsMap';
import { ReactComponent as KMZIcon } from '../../atoms/icons/kmz.svg';
import { ReactComponent as MapIcon } from '../../atoms/icons/map.svg';

const containerStyle = {
  width: '100%',
  height: '90vh',
  flexBasis: '100%',
  borderRadius: '10px',
};

const center = {
  lat: 49.024502,
  lng: 31.419201,
};

interface GMapProps {
  handleMarkerClick?: (segment: VideoInfo) => void;
  activeSegment?: VideoInfo | null;
}

interface LatLng {
  lat: number;
  lng: number;
}

const GMap: React.FC<GMapProps> = ({ handleMarkerClick, activeSegment }) => {
  const videoList = useRecoilValue(filteredVideoListState);
  const [mapRef, setMapRef] = useState<google.maps.Map | null>(null);
  const [infoWindow, setInfoWindow] = useState<google.maps.InfoWindow | null>(
    null,
  );
  const [markerPosition, setMarkerPosition] = useState<LatLng | undefined>();
  const [, setSelectedSegment] = useState<VideoInfo | null>(null);
  const dataLayerRef = useRef<google.maps.Data.Feature[]>([]);
  const dataLayerClickRef = useRef<google.maps.MapsEventListener | null>();
  const [polineRef, setPolineRef] = useState<google.maps.Polyline | null>(null);
  const [map, setMap] = React.useState<google.maps.Map>();
  const [tileFiles, setTileFiles] = useState<Record<string, string>>({});

  const handleImportKmz = async (e: ChangeEvent<HTMLInputElement>) => {
    e.stopPropagation();
    const kmzFiles = Array.from((e.target as HTMLInputElement)?.files ?? []);

    if (kmzFiles?.length) {
      const filesData = await loadFiles(kmzFiles);

      const geoJSONList = [];
      let allIcons: Record<string, string> = {};
      for (const [kmzFileName, kmzData] of filesData) {
        const geoJSON = await parse(kmzData, { name: kmzFileName, icons: {} });
        geoJSONList.push(geoJSON);
        allIcons = { ...allIcons, ...(geoJSON as any).properties.icons };
      }

      // geojson to google map's dataLayer
      if (mapRef) {
        // cleanup previous layers first
        dataLayerRef.current.forEach((layer) => {
          mapRef.data.remove(layer);
        });

        // Set the icon from the "icon" property in the geoJson
        mapRef.data.setStyle((feature) => {
          let icon;
          if (feature.getProperty('icon')) {
            const iconName = feature.getProperty('icon') as string;
            icon = allIcons[iconName];
          }
          return { icon };
        });

        dataLayerRef.current = geoJSONList
          .map((geoJSON) => {
            return mapRef.data.addGeoJson(geoJSON);
          })
          .flat();

        google.maps.event.removeListener(dataLayerClickRef.current!);
        dataLayerClickRef.current = mapRef.data.addListener(
          'click',
          (event: any) => {
            if (!infoWindow) return;

            const feature = event.feature;
            let description = feature.getProperty('description');
            if (description && description['@type'] === 'html') {
              description = description.value;
              // TODO: one more dirty image hack
              description = description.replace(
                /(<img[^>]+?src=")([^"]+)("[^>]+>)/g,
                (
                  match: string,
                  startTag: string,
                  iconName: string,
                  entTag: string,
                ) => {
                  const iconSrc = allIcons[iconName];
                  return `${startTag}${iconSrc}${entTag}`;
                },
              );
            }
            let html =
              '<b>' + feature.getProperty('name') + '</b><br>' + description;
            if (feature.getProperty('link')) {
              html +=
                "<br><a class='normal_link' target='_blank' href='" +
                feature.getProperty('link') +
                "'>link</a>";
            }
            infoWindow.setContent(html);
            infoWindow.setPosition(event.latLng);
            infoWindow.setOptions({
              pixelOffset: new google.maps.Size(0, -34),
            });
            infoWindow.open(mapRef);
          },
        );
      }
    }
  };

  const loadTileFilesFromFolder = async (
    directoryHandle: FileSystemDirectoryHandle,
    path: string,
  ): Promise<Record<string, string>> => {
    const files: Record<string, string> = {};

    for await (const entry of (directoryHandle as any).values()) {
      const currentPath = path ? `${path}/${entry.name}` : entry.name;

      if (entry.kind === 'file' && entry.name.endsWith('.png')) {
        const fileHandle = await (entry as FileSystemFileHandle).getFile();
        files[currentPath] = URL.createObjectURL(fileHandle);
      } else if (entry.kind === 'directory') {
        // Recursively handle subfolders
        const subfolderFiles = await loadTileFilesFromFolder(
          entry as FileSystemDirectoryHandle,
          currentPath,
        );
        Object.assign(files, subfolderFiles);
      }
    }

    return files;
  };

  const selectFolder = async (): Promise<void> => {
    try {
      const directoryHandle = await (window as any).showDirectoryPicker();
      const files = await loadTileFilesFromFolder(directoryHandle, '');
      setTileFiles(files);
      console.log('folder selected', files);
    } catch (err) {
      console.error('Error selecting folder:', err);
    }
  };

  useEffect(() => {
    return () => {
      if (mapRef) {
        // cleanup listeners on unmount
        google.maps.event.removeListener(dataLayerClickRef.current!);
      }
    };
  }, [mapRef]);

  useEffect(() => {
    if (Object.keys(tileFiles).length > 0 && mapRef) {
      const customTileLayer = new window.google.maps.ImageMapType({
        getTileUrl: (coord: google.maps.Point, zoom: number): string => {
          const tilePath = `${zoom}/${coord.x}/${(1 << zoom) - 1 - coord.y}.png`;
          console.log('Requested tile URL:', tilePath); // Debug log
          return tileFiles[tilePath] || ''; // Return the local file URL if found
        },
        tileSize: new window.google.maps.Size(256, 256),
        name: 'LocalTiles',
        maxZoom: 21, // Adjust according to your tiles
        minZoom: 0,
        opacity: 1,
      });
      console.log('tiles loaded');
      mapRef.overlayMapTypes.insertAt(0, customTileLayer);
    }
  }, [tileFiles, mapRef]);

  useEffect(() => {
    if (mapRef && videoList.length > 0) {
      const bounds = new google.maps.LatLngBounds();
      videoList.forEach((segment) => {
        if (segment.location) {
          bounds.extend(
            new google.maps.LatLng(segment.location.lat, segment.location.lng),
          );
        }
      });
      mapRef.fitBounds(bounds);
    }
  }, [mapRef, videoList]);

  useEffect(() => {
    if (activeSegment && activeSegment.location) {
      setMarkerPosition(activeSegment.location);
    }
  }, [activeSegment]);

  useEffect(() => {
    const loc = activeSegment ? activeSegment.location : markerPosition;
    if (loc) {
      mapRef?.setCenter(loc);
    }
  }, [activeSegment, markerPosition]);

  const handleMarkerClickInternal = (segment: VideoInfo) => {
    setSelectedSegment(segment);
    setMarkerPosition(segment.location);
    if (handleMarkerClick) {
      handleMarkerClick(segment);
    }
  };

  const getPathCoordinates = (fileName: string): LatLng[] => {
    return videoList
      .filter((segment) => segment.fileName === fileName && segment.location)
      .map((segment) => segment.location as LatLng);
  };

  const uniqueVideos = Array.from(
    new Set(videoList.map((segment) => segment.fileName)),
  );

  const { getIconSrc } = useMarkersIconsMap();

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
  });

  if (!isLoaded) return <>Loading...</>;
  return (
    <div className="map_container">
      <div className="map_controls">
        <label htmlFor="kmzImport">
          <KMZIcon
            style={{
              position: 'absolute',
              top: '135px',
              right: '15px',
              zIndex: 1000,
              width: '35px',
              border: 'none',
              padding: '10px 15px',
              borderRadius: '5px',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
            }}
          />
        </label>
        <input
          id={'kmzImport'}
          type="file"
          accept=".kmz,.kml"
          multiple
          onChange={handleImportKmz}
          style={{
            display: 'none',
            position: 'fixed',
            pointerEvents: 'auto',
            zIndex: 1000,
          }}
        />
        <label htmlFor="mapTiles">
          <MapIcon
            style={{
              position: 'absolute',
              top: '175px',
              right: '15px',
              zIndex: 1000,
              width: '35px',
              border: 'none',
              padding: '10px 15px',
              borderRadius: '5px',
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
            }}
          />
        </label>
        <button
          id={'mapTiles'}
          onClick={selectFolder}
          style={{ display: 'none', pointerEvents: 'auto' }}
        ></button>
      </div>
      <div>
        <GoogleMap
          mapContainerStyle={containerStyle}
          options={{
            mapId: '90f87356969d889c',
            gestureHandling: 'greedy',
            mapTypeId: 'hybrid',
            zoom: mapRef?.getZoom() || 6,
          }}
          onLoad={(map) => {
            setMapRef(map);
            setInfoWindow(new google.maps.InfoWindow());
            map.setCenter(
              activeSegment ? activeSegment.location : markerPosition || center,
            );
          }}
        >
          {uniqueVideos.map((video, index) => (
            <PolylineF
              key={index}
              path={getPathCoordinates(video)}
              options={{
                strokeColor: '#FF0000',
                strokeOpacity: 1.0,
                strokeWeight: 2,
                visible: activeSegment?.fileName === video,
                icons: [
                  {
                    icon: {
                      path: google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                    },
                    offset: '100%',
                    repeat: '30%',
                  },
                ],
              }}
              onLoad={(polyline) => {
                setPolineRef(polyline);
              }}
            />
          ))}
          {videoList.map((segment, index) => {
            // TODO duplicated with Editor's GMap
            const url = (() => {
              const isCurrentVideo =
                activeSegment && activeSegment.fileName === segment.fileName;
              const isCurrentSegment = activeSegment === segment;
              if (segment.type) {
                const iconStyle = isCurrentVideo
                  ? isCurrentSegment
                    ? 'active'
                    : 'otherSegment'
                  : 'default';
                return getIconSrc(segment.type, iconStyle);
              }

              // default markers
              return isCurrentVideo
                ? isCurrentSegment
                  ? 'http://maps.google.com/mapfiles/ms/icons/green-dot.png'
                  : 'http://maps.google.com/mapfiles/ms/icons/red-dot.png'
                : 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png';
            })();

            return (
              segment.location && (
                <MarkerF
                  key={index}
                  position={segment.location}
                  onClick={() => handleMarkerClickInternal(segment)}
                  icon={{
                    url,
                    scaledSize: new google.maps.Size(32, 32),
                  }}
                />
              )
            );
          })}
        </GoogleMap>
      </div>
    </div>
  );
};

export default GMap;
