import React from 'react';
import { TimeCodeType } from '../../atoms/video';
import { useMarkersIconsMap } from '../../hooks/useMarkersIconsMap';

export const MarkerImg = ({ type }: { type: TimeCodeType }) => {
  const { getIcon } = useMarkersIconsMap();
  if (!type) return null;
  const icon = getIcon(type, 'active');

  return <div style={{ height: '32px', width: '32px' }}>{icon}</div>;
};
