import React from 'react';
import { VideoInfo } from '../../atoms/video';
import { InputType } from './types';
import { InputNode } from './InputNode';

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean;
  dataIndex: string;
  title: string;
  inputType: InputType;
  record: VideoInfo;
  index: number;
}

const EditableCell: React.FC<React.PropsWithChildren<EditableCellProps>> = ({
  editing,
  dataIndex,
  title,
  inputType,
  children,
  ...restProps
}) => {
  return (
    <td {...restProps}>
      {editing ? (
        <InputNode inputType={inputType} title={title} name={dataIndex} />
      ) : (
        children
      )}
    </td>
  );
};

export default EditableCell;
