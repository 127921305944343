import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Player from '../components/Player/Player';
import Table from '../components/Table/Table';
import useAuth from '../hooks/useAuth';
import { VideoInfo, MapPosition } from '../atoms/video';
import { hmsToSeconds } from '../utils/timeUtils';
import ContentLayout from '../layout/ContentLayout/ContentLayout';
import { Drawer, Button, Splitter, Flex } from 'antd';
import { EditorGMap } from '../components/GMap/EditorGMap';

const Editor: React.FC = () => {
  const { user, role, loading } = useAuth();
  const navigate = useNavigate();
  const videoRef = useRef<HTMLVideoElement>(null);
  const [currentSegment, setCurrentSegment] = useState<VideoInfo | null>(null);

  const [isDrawerVisible, setDrawerVisible] = useState(false);
  const [editorMapPosition, setEditorMapPosition] = useState<MapPosition>();

  const toggleDrawer = () => {
    setDrawerVisible(!isDrawerVisible);
  };

  useEffect(() => {
    if (!loading && !user) {
      navigate('/auth');
    }

    if (role && role !== 'editor') {
      navigate('/viewer');
    }
  }, [loading, user, navigate, role]);

  const handleMarkerClick = (segment: VideoInfo) => {
    if (videoRef.current) {
      videoRef.current.currentTime = hmsToSeconds(segment.startTime);
      videoRef.current.play();
    }
    setCurrentSegment(segment);
  };

  const handleRowClick = (segment: VideoInfo) => {
    handleMarkerClick(segment);
    toggleDrawer();
  };

  if (loading) {
    return <div>Loading...</div>; // Display a loading message or spinner while checking auth state
  }

  if (!user) {
    return null; // Or a loading spinner
  }

  return (
    <ContentLayout>
      <Splitter>
        <Splitter.Panel defaultSize="65%" min="20%" max="70%">
          <Flex
            justify="left"
            align="left"
            style={{ height: '100%', width: '100%' }}
          >
            <Player
              segmentStartTime={currentSegment?.startTime}
              segmentTitle={currentSegment?.title}
              mapPosition={editorMapPosition}
            />
          </Flex>
        </Splitter.Panel>
        <Splitter.Panel>
          <Flex justify="center" align="center">
            <EditorGMap
              activeSegment={currentSegment}
              handleMarkerClick={handleMarkerClick}
              handleMapPositionUpdate={setEditorMapPosition}
            />
          </Flex>
        </Splitter.Panel>
      </Splitter>
      <Drawer
        placement="bottom"
        height="85%"
        closable={true}
        onClose={toggleDrawer}
        open={isDrawerVisible}
        styles={{
          body: {
            padding: 0,
            position: 'relative',
            overflow: 'hidden',
            zIndex: 2,
          },
        }}
      >
        <Table activeSegment={currentSegment} onClick={handleRowClick} />
      </Drawer>
      {!isDrawerVisible && (
        <Button
          type="primary"
          style={{
            position: 'absolute',
            width: '100px',
            bottom: '-7px',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 1000,
            borderRadius: '8px 8px 0 0',
            boxShadow: '0px 2px 4px rgba(0,0,0,0.1)',
            transition: 'bottom 0.3s ease',
            background: '#31333980',
          }}
          onClick={toggleDrawer}
        >
          Show Data
        </Button>
      )}
    </ContentLayout>
  );
};

export default Editor;
