import { Button, Col, Form, Modal, Row, Typography } from 'antd';
import { useRecoilState } from 'recoil';
import {
  VideoInfo,
  videoInfoState,
  videoListState,
  Location,
  MapPosition,
} from '../../atoms/video';
import { InputText, TimecodeTypeSelect } from '../RecordForm/Fields';
import React from 'react';
import { RecordModalGMap } from './RecordModalGMap';

interface RecordModalProps {
  open: boolean;
  onClose: () => void;
  mapPosition?: MapPosition;
}

type RecordModalForm = Pick<
  VideoInfo,
  'startTime' | 'endTime' | 'title' | 'location' | 'type'
>;

const parseLocation = (location: string) => {
  const [lat, lng] = location.split(',').map(Number);
  return { lat, lng };
};

export const RecordModal = ({
  open,
  mapPosition,
  onClose,
}: RecordModalProps) => {
  const [form] = Form.useForm();
  const [videoInfo] = useRecoilState(videoInfoState);
  const [, setVideoList] = useRecoilState(videoListState);

  const { location: locationValue, type: typeValue } =
    Form.useWatch(
      (values) => ({
        location: parseLocation(values.location || ''),
        type: values.type ?? '',
      }),
      form,
    ) ?? videoInfo;

  const handleOk = (values: RecordModalForm) => {
    const location = parseLocation(values.location as unknown as string);

    setVideoList((prev) => [
      ...prev,
      {
        fileName: videoInfo.fileName,
        key: videoInfo.key,

        // form values
        ...values,
        // form location to Object
        location,
      },
    ]);

    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  const handleLocationChange = (location: Location) => {
    form.setFieldValue('location', `${location.lat}, ${location.lng}`);
  };

  return (
    <Modal
      forceRender
      title="Add Record"
      open={open}
      width="80vw"
      onCancel={handleCancel}
      modalRender={(dom) => (
        <Form
          layout="horizontal"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 14 }}
          form={form}
          initialValues={{
            ...videoInfo,
            location: `${videoInfo.location.lat}, ${videoInfo.location.lng}`,
          }}
          onFinish={(values) => handleOk(values)}
        >
          {dom}
        </Form>
      )}
      footer={[
        <Button key="back" type="default" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button key="submit" type="primary" htmlType="submit">
          Save
        </Button>,
      ]}
    >
      <Row>
        <Col span={12}>
          <Form.Item label="File name" name="fileName">
            <Typography.Text strong>{videoInfo.fileName}</Typography.Text>
          </Form.Item>
          <InputText label="Start time" name="startTime" />
          <InputText label="End time" name="endTime" />
          <InputText label="Title" name="title" />
          <InputText label="Location" name="location" />
          <TimecodeTypeSelect label="Type" name="type" />
        </Col>
        <Col span={12}>
          <RecordModalGMap
            newRecord={{
              ...videoInfo,
              location: locationValue,
              type: typeValue,
            }}
            mapPosition={mapPosition}
            onLocationUpdate={handleLocationChange}
          />
        </Col>
      </Row>
    </Modal>
  );
};
