import React from 'react';
import { useRecoilValue } from 'recoil';
import {
  filteredVideoListState,
  VideoInfo,
  Location,
  MapPosition,
} from '../../atoms/video';
import GMap, { GMapProps } from '../GMap/GMap';

type Props = Omit<GMapProps, 'videoList' | 'markerLocationUpdate'> & {
  newRecord: VideoInfo;
  mapPosition?: MapPosition;
  onLocationUpdate: (location: Location) => void;
};

export const RecordModalGMap: React.FC<Props> = ({
  newRecord,
  onLocationUpdate,
  mapPosition,
  ...props
}) => {
  const videoList = useRecoilValue(filteredVideoListState);

  const handleVideoListUpdate = (
    updatedRecord: VideoInfo,
    location: Location,
  ) => {
    if (updatedRecord.key !== newRecord.key) {
      return;
    }

    onLocationUpdate(location);
  };

  // let's drag only new point
  const canDragMarker = (record: VideoInfo) => {
    return record.key === newRecord.key;
  };

  return (
    <GMap
      activeSegment={newRecord}
      videoList={[...videoList, newRecord]}
      markerLocationUpdate={handleVideoListUpdate}
      handleMapClick={onLocationUpdate}
      mapStyles={{ height: '70vh' }}
      carDragMarker={canDragMarker}
      mapPosition={mapPosition}
      {...props}
    />
  );
};
