import React from 'react';
import { InputType } from './types';
import { InputText, TimecodeTypeSelect } from '../RecordForm/Fields';

interface InputNodeProps {
  inputType: InputType;
  name: string;
  title: string;
}

export const InputNode = ({ inputType, ...restProps }: InputNodeProps) => {
  switch (inputType) {
    case 'timecode_type_select':
      return <TimecodeTypeSelect {...restProps} />;

    default:
      return <InputText {...restProps} style={{ margin: 0 }} />;
  }
};
